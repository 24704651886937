import { cn } from "@fscrypto/ui";
import { VariantProps } from "class-variance-authority";
import { backgroundVariants, textVariants } from "./style-variants";

export interface EcoPillProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof backgroundVariants> {
  children: React.ReactNode;
  outlined?: boolean;
}
// Need to explicitly build out the EcoText in this component, and only apply it if it's outlined. And hide the EcoText for a contrast text otherwise.
export const EcoPill = ({ variant, outlined, children, className, ...props }: EcoPillProps) => {
  return (
    <div className={cn("flex rounded-full", backgroundVariants({ variant }), className)} {...props}>
      {outlined ? (
        <div className="m-1 w-full overflow-x-hidden rounded-full bg-white px-5 py-3 dark:bg-black">
          <div
            className={cn(
              "bg-clip-text text-xl text-transparent [-webkit-background-clip:text]",
              textVariants({ variant }),
            )}
          >
            {children}
          </div>
        </div>
      ) : (
        children
      )}
    </div>
  );
};
